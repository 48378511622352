<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<div class="list-box">
					<div v-if="corpdiv == '31'" class="tit">일반 프로젝트 &gt; 소속인재 프로젝트 인터뷰 현황</div>
					<div v-else class="tit">일반 프로젝트 &gt; 프로젝트 인터뷰 현황</div>
					<div class="search-box interval">
						<SelectComp type="select" title="프로젝트 상태" list="4:제안중,2:수행예정,1:수행중" :isAll="true" v-model="srchFilter.projectStatus" /><nbsp/>
						<SelectComp type="select" title="프로젝트명" list="1:프로젝트명,2:고객사명" v-model="srchFilter.searchGubun" />						
						<InputComp type="text" :placeholder="srchFilter.searchGubun ==  '1' ? '프로젝트명' : '고객사명'" v-model="srchFilter.srcTxt" @keyup.enter="getDcoPgmPrjAppPrjList('1')" />
						<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getDcoPgmPrjAppPrjList('1')"/>
					</div>
					<div class="filter">
						<div class="filter_box">
							<ul>
								<li @click="changeOrderGubun('1')"><img src="/images/icon_filter.png" alt="최신 등록순" :class="srchFilter.orderGubun == '1' ? 'filter' : ''"/><p>최신 등록순</p><span>ㅣ</span></li>
								<li @click="changeOrderGubun('2')"><img src="/images/icon_filter.png" alt="지원 적은순" :class="srchFilter.orderGubun == '2' ? 'filter' : ''"/><p>지원 적은순</p><span>ㅣ</span></li>
								<li @click="changeOrderGubun('3')"><img src="/images/icon_filter.png" alt="지원 많은순" :class="srchFilter.orderGubun == '3' ? 'filter' : ''"/><p>지원 많은순</p><span>ㅣ</span></li>
								<li @click="changeOrderGubun('4')"><img src="/images/icon_filter.png" alt="마감 임박순" :class="srchFilter.orderGubun == '4' ? 'filter' : ''"/><p>마감 임박순</p></li>
							</ul>
						</div>
					</div>

					<div class="Board type3">
						<table class="Board_type3 prj list-all" style="margin-left: 0;">
							<colgroup> 
								<col width="3%">
								<col width="*">
								<col width="12%">
								<col width="15%">
								<col width="10%">
								<col width="10%">
								<col width="18%">
							</colgroup>
							<thead>
								<tr>
									<th>NO</th>
									<th>프로젝트명</th>
									<th>고객사명</th>
									<th>프로젝트 기간</th>
									<th>등록일</th>
									<th>프로젝트 상태</th>
									<th>인터뷰 결과</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(project, index) in prjList" :key="index">
									<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
									<td class="score prj-name">
										<div v-if="project.recrEndDateDiff >= 0 || project.projectStatus == 4" class="rc_tag" >
											<div v-if="project.projectStatus == 4" class="rc_mark_black">제안단계</div>
											<div v-if="project.recrEndDateDiff >= 0" class="rc_mark">+모집중</div>
										</div>
										{{project.projectNm}}
									</td>
									<td class="score prj-name">{{project.clientNm}}</td>
									<td class="score">{{project.projectStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{project.projectEndYyyymmdd | date('yyyy.MM.dd')}}</td>
									<td class="score">{{project.regYyyymmdd | date('yyyy.MM.dd')}}</td>
									<td class="score">
										<span v-if="project.projectStatus == 1"><div class="state pf"></div>수 행 중</span>
										<span v-if="project.projectStatus == 4"><div class="state us"></div>제 안 중</span>
										<span v-if="project.projectStatus == 2"><div class="state ep"></div>수행예정</span>
										<span v-if="project.projectStatus == 9"><div class="state cp"></div>수행완료</span>
									</td>
									<td class="score resume">
										<!-- <div class="mem">{{project.appMberCnt}}명</div>
										<div v-if="project.appMberCnt != 0" class="btn" style="min-width: 110px;" @click="clickProjectDetail(project)">지원인재 보기 &#9654;</div> -->
										<div class="btn" style="min-width: 110px;" @click="clickProjectDetail(project)">인터뷰 결과 보기 &#9654;</div>
									</td>
								</tr>
								
								<!-- 등록된 프로젝트가 없을 경우 -->
								<tr v-if="prjList.length <= 0">
									<td colspan="7" class="none">프로젝트가 없습니다!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- 페이징부분 -->
					<pagingComp :pageInfo="pageInfo" @page-click="goPage" />
				</div>
			</div>
			<!-- footer -->
			<!-- <footer-layout type="left"/> -->
		</div>
	</div>	
</template>

<script>
// import gradeImg from "@/components/highpro/GradeImg.vue"
import pagingComp from '@/components/PagingComp.vue';
// import tecProfile from "@/components/highpro/TecProfile.vue";
// import footerLayout from "@/components/layout/footer.vue";
// import fixTop from "@/components/FixTop.vue";

export default {
	data() {
		return {
			pageInfo : {},
			corpdiv : this.$store.state.userInfo.mberDivCd,

			srchFilter : {
				srcTxt : '',
				orderGubun : 1, 
				pageIndex : 1,
				// projectStatus : '',
				searchGubun : 1,
			},

			// tecMberSeq : '',
			// mberInfoYn : '',
			// detailObj : {},
			prjList :[],

			// selectedPrjIdx : 0,
			// selectedOrdCol : '1',

			nowYyyymmdd : new Date().format("yyyyMMdd"),
		}
	},

	components : {
		pagingComp
	},

	beforeMount() {
		// console.log('this param', this.$route.params.srchName);
		// if(this.$route.params.srchName != '') {
		// 	this.srcFilter.srcTxt = this.$route.params.srchName;
		// }
		this.getDcoPgmPrjAppPrjList();
	},

	mounted(){
        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu':false})
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		getDcoPgmPrjAppPrjList(div) {
			var param = this.srchFilter;
			param.pageUnit = 20;
			param.pageSize = 10;
			param.corpdiv = this.corpdiv;
			param.interviewPage = 'Y';
			param.proc = 'Y';
			if(div) this.srchFilter.pageIndex = 1;

			this.$.httpPost('/api/prj/getProjectAppList', param)
				.then((res) => {
					// console.log(res.data)

					this.prjList = res.data.prjList;
					this.pageInfo = res.data.pageInfo;
				});
		},

		// getMberList(seq, idx, div) {
		// 	this.prjList[idx].ordColDiv = div;
			
		// 	var ordCol = 'eval';
		// 	var sort = 'high';
		// 	if('3, 4'.indexOf(div) > -1) ordCol = 'eval';
		// 	if('2, 4'.indexOf(div) > -1) sort = 'low';
			
		// 	var param = {};
		// 	param.projectSeq = seq;
		// 	param.ordCol = ordCol;
		// 	param.sort = sort;
		// 	param.corpdiv = this.corpdiv;

		// 	this.$.httpPost('/api/prj/dco/pmg/getDcoPgmPrjAppMberList', param)
		// 		.then((res) => {
		// 			this.prjList[idx].mberList =  res.data.mberList;
		// 		});
		// },

		// 정렬 순서
		changeOrderGubun(orderGubun) {
			this.srchFilter.orderGubun = orderGubun;
			this.srchFilter.pageIndex = 1;
			this.getDcoPgmPrjAppPrjList();
		},

		// 페이지 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getDcoPgmPrjAppPrjList();
		},

		clickProjectDetail(project) { 
			if(this.$store.state.userInfo.mberDivCd == '31') {
				this.$router.push({name : 'PRJ404M02', params : { projectSeq : project.projectSeq}});	
			} else {
				// this.$router.push({name : 'PRJ305M02'});
				this.$router.push({name : 'PRJ306M02', params : { projectSeq : project.projectSeq, caller : { name : 'PRJ306M01'}}});
			}
			
		}
	}
}
</script>